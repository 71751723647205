import tag from 'clean-tag'
import { space, textAlign, width } from 'styled-system'

import blacklist from '../../../constants/blacklistAttrs'
import styled, { css } from '../../styled'
import { IProp } from './types'

function themeCard(props: IProp) {
  const {
    dark,
    theme: { colors },
  } = props

  if (dark) {
    return css`
      background-color: rgba(255, 255, 255, 0.06);
    `
  }

  return css`
    background: ${colors.grayscale.white};
  `
}

function clickableStyle(props: IProp) {
  const { clickable, theme } = props
  if (!clickable) {
    return null
  }

  return css`
    transition: all ${theme.transition.ease()};

    &:hover {
      border: 1px solid ${theme.colors.grayscale.mediumLighter};
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    }
  `
}

export const cardStyle = css`
  padding: 2em;
  box-shadow: ${({ theme: { shadow } }) => shadow.default};
  border: 1px solid rgba(83, 98, 124, 0.07);
  border-radius: 4px;
  overflow: hidden;
  ${space}
  ${width}
  ${themeCard}
  ${textAlign}
  ${clickableStyle}
`

export default styled(tag).attrs({ blacklist })<IProp>`
  ${cardStyle};
`
