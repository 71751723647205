import { graphql, StaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import * as React from 'react'

import getImageSource from '../../../utils/getImageSource'
import { IProp } from './types'

export default ({ path, alt, ...props }: IProp) => {
  return (
    <StaticQuery
      query={graphql`
        query QueryImages {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 1200) {
                  originalName
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      `}
      render={({ allImageSharp }) => {
        const data = getImageSource(allImageSharp)

        return <Image fluid={data[path]} alt={alt} {...props} />
      }}
    />
  )
}
